<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Resolve Complaint</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Note"
              invalid-feedback="Note is required."
              ref="note"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter note"
                name="classField"
                ref="note"
                v-model.trim="resObj.note"
                :disabled="disabledFields"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="date is required."
              ref="date"
            >
              <flat-pickr
                ref="date"
                :config="config"
                v-model.trim="resObj.resolvedOn"
                class="form-control"
                placeholder="Select date"
                :disabled="disabledFields"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Resolved By"
              invalid-feedback="resolved by is required."
              ref="resBy"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter name"
                name="classField"
                ref="resBy"
                v-model.trim="resObj.resolvedBy"
                :disabled="disabledFields"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" v-if="!disabledFields">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-1"
              @click="save()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Resolve </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility3"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add Complaint</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility3 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row v-if="compObj.sID == 0">
          <b-col md="12">
            <b-input-group>
              <b-input-group-prepend
                is-text
                @click="loadSearch()"
                style="cursor: pointer"
              >
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input
                v-model="stdQuery"
                @keyup.enter="loadSearch()"
                placeholder="Search Student"
              >
              </b-form-input>
            </b-input-group>
          </b-col>
          <b-col md="12">
            <b-table
              class="mt-1"
              show-empty
              ref="selectableTable"
              :items="students"
              :fields="stdFields"
              :busy="studentLoading"
              responsive
              hover
              selectable
              select-mode="single"
              @row-selected="getStudent"
              small
            >
              <template #table-busy>
                <div
                  class="d-flex justify-content-center mb-3"
                  style="margin-top: 50px"
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    variant="primary"
                    label="Spinning"
                  ></b-spinner>
                </div>
              </template>
              <template #head(details)="data">
                <div class="text-center">
                  <span>{{ data.label }}</span>
                </div>
              </template>
              <template #cell(details)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    size="42"
                    :src="data.item.picture"
                    variant="light-secondary"
                  />
                  <div class="chat-info flex-grow-1 ml-2">
                    <h5 class="mb-0">
                      {{ data.item.name }}
                    </h5>
                    <p class="card-text text-truncate">
                      {{ data.item.meta_class }}
                    </p>
                  </div>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <b-form-group label="Student">
              <b-col class="mb-1 d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <b-avatar
                    size="42"
                    :src="currentStd.picture"
                    variant="light-secondary"
                  />
                  <div class="chat-info flex-grow-1 ml-2">
                    <h5 class="mb-0">
                      {{ currentStd.name }}
                    </h5>
                    <p class="card-text text-truncate">
                      {{ currentStd.meta_class }}
                    </p>
                  </div>
                </div>
                <b-button
                  @click="compObj.sID = 0"
                  class="btn-icon ml-3"
                  v-b-tooltip.hover.right
                  title="Remove"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-danger"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-col>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Complaint Category"
              invalid-feedback="Complaint Category is required."
              ref="catId"
            >
              <v-select
                v-model="compObj.categoryID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categories"
                :clearable="false"
                ref="catId"
                label="category"
                :reduce="(val) => val.id"
                placeholder="Select category"
                @input="CheckCatID()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Date"
              invalid-feedback="date is required."
              ref="comp_date"
            >
              <flat-pickr
                ref="comp_date"
                :config="config"
                v-model="compObj.date"
                class="form-control"
                placeholder="Select date"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Complaint For"
              invalid-feedback="Complaint For is required."
              ref="about"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="e.g Teacher name"
                name="classField"
                ref="about"
                v-model="compObj.about"
                @focusout="CheckAbout()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Complaint By"
              invalid-feedback="Complaint by is required."
              ref="compBy"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter name"
                name="classField"
                ref="compBy"
                v-model="compObj.complaintBy"
                @focusout="CheckCompBy()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Complaint"
              invalid-feedback="Complaint is required."
              ref="details"
            >
              <b-form-textarea
                ref="details"
                placeholder="Write complaint"
                v-model="compObj.details"
                rows="5"
                @input="CheckDetails()"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-1"
              @click="saveComplaint()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <!-- <b-row>
      <b-col xl="3" lg="3" md="6" sm="6">
        <b-card>
          <h4 class="card-title">Today</h4>
          <p class="card-text font-small-6 font-weight-bolder mb-0">
            <span>5</span>
          </p>
        </b-card>
      </b-col>
      <b-col xl="3" lg="3" md="6" sm="6">
        <b-card>
          <h4 class="card-title">Last Week</h4>
          <p class="card-text font-small-6 font-weight-bolder mb-0">
            <span>12</span>
          </p>
        </b-card>
      </b-col>
      <b-col xl="3" lg="3" md="6" sm="6">
        <b-card>
          <h4 class="card-title">Last Month</h4>
          <p class="card-text font-small-6 font-weight-bolder mb-0">
            <span>6</span>
          </p>
        </b-card>
      </b-col>
    </b-row> -->

    <b-card>
      <b-row class="mt-1" align-h="end">
        <!-- <b-col xl="2" lg="3" md="4" sm="12" cols="12">
          <b-button
            @click="AddOpen()"
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Complaint</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          xl="10"
          lg="9"
          md="8"
          sm="12"
          cols="12"
        >
          <b-form-group class="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col> -->

        <b-col xl="2" lg="3" md="3" sm="12" cols="12">
          <b-button
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="currentFilter == '' ? 'primary' : 'outline-primary'"
            class="mb-1 mb-md-0"
            @click="LoadGrid('')"
          >
            <span class="align-middle">Today</span>
          </b-button>
        </b-col>
        <b-col xl="2" lg="3" md="3" sm="12" cols="12">
          <b-button
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="
              currentFilter == 'pending' ? 'primary' : 'outline-primary'
            "
            class="mb-1 mb-md-0"
            @click="LoadGrid('pending')"
          >
            <span class="align-middle">Pending</span>
          </b-button>
        </b-col>
        <b-col xl="2" lg="3" md="3" sm="12" cols="12">
          <b-button
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="
              currentFilter == 'resolved' ? 'primary' : 'outline-primary'
            "
            class="mb-1 mb-md-0"
            @click="LoadGrid('resolved')"
          >
            <span class="align-middle">Resolved</span>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="gridLoading"
        show-empty
        responsive
        hover
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(student)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="44"
                :src="data.item.std.picture"
                variant="light-primary"
              />
            </template>

            <b-link
              class="font-weight-bold d-block text-nowrap"
              style="margin-bottom: 4px"
            >
              <!-- {{ data.item.name }} -->
              <b-badge variant="light-primary">
                {{ data.item.std.name }}
              </b-badge>
            </b-link>
            <small class="text-muted">
              <b-badge variant="light-primary">
                GR - {{ data.item.std.grNo }}
              </b-badge>
              <b-badge variant="light-primary" class="ml-50">
                {{ data.item.std.meta_class }}
              </b-badge>
            </small>
          </b-media>
        </template>

        <template #cell(date)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.item.us.date).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>

        <template #cell(name)="data">
          <b-badge variant="light-primary">
            {{ !data.item.ts ? "Admin" : data.item.ts.name }}
          </b-badge>
        </template>

        <template #cell(complaint)="data">
          {{ data.item.us.complaint }}
        </template>

        <template #cell(status)="data">
          <b-badge variant="light-primary">
            <span>{{ data.item.us.status }}</span>
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-center">
            <b-button
              v-if="data.item.us.status == 'pending'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Resolve"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="resolve(data.item.us)"
            >
              <feather-icon icon="CheckIcon" />
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="View"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="resolve(data.item.us)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BModal,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    //Multiselect,
    BFormTextarea,
    BMediaAside,
    BMedia,
    BAvatar,
    BModal,
    flatPickr,
    BImg,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    this.LoadGrid("");
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.us.complaintBy.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      categories: [],
      request: false,
      gridLoading: false,
      colorDep: [],
      visibility2: false,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      sidebarTitle: "Add Complaint Category",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "student", key: "student" },
        { label: "date", key: "date" },
        { label: "complaint by", key: "name" },
        { label: "complaint", key: "complaint" },
        // { label: "status", key: "status" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      rangeDate: null,
      myObj: {
        id: 0,
        category: "",
        campusID: this.$store.state.userData.cId,
      },
      resObj: {},
      currentFilter: "",
      disabledFields: false,
      compObj: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        categoryID: 0,
        sID: 0,
        about: "",
        details: "",
        date: "",
        status: "pending",
        complaintBy: "",
        note: "",
        resolvedBy: "",
        resolvedOn: null,
      },
      visibility3: false,
      stdFields: [{ label: "Students", key: "details" }],
      students: [],
      studentLoading: false,
      stdQuery: "",
      currentStd: {},
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
    async loadSearch() {
      this.studentLoading = true;
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "Students/Search?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&keyword=" +
          this.stdQuery,
        token: this.$store.state.userData.token,
      });
      this.students = myitem;
      // console.log("stud", this.students);
      this.studentLoading = false;
    },
    getStudent(row) {
      this.compObj.sID = row[0].id;
      this.currentStd = row[0];
    },
    async AddOpen() {
      this.compObj = {
        id: 0,
        campusID: this.$store.state.userData.cId,
        categoryID: 0,
        sID: 0,
        about: "",
        details: "",
        date: new Date().toJSON(),
        status: "pending",
        complaintBy: "",
        note: "",
        resolvedBy: "",
        resolvedOn: null,
      };
      this.visibility3 = true;
      var elem = this.$refs["details"];
      elem.state = undefined;
      var elem = this.$refs["compBy"];
      elem.state = undefined;
      var elem = this.$refs["about"];
      elem.state = undefined;
      var elem = this.$refs["catId"];
      elem.state = undefined;
    },
    CheckStudent() {
      var elem = this.$refs["catId"];
      if (this.compObj.sID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCatID() {
      var elem = this.$refs["catId"];
      if (this.compObj.categoryID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAbout() {
      var elem = this.$refs["about"];
      if (this.compObj.about == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCompBy() {
      var elem = this.$refs["compBy"];
      if (this.compObj.complaintBy == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDetails() {
      var elem = this.$refs["details"];
      if (this.compObj.details.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveComplaint() {
      this.CheckCatID();
      this.CheckAbout();
      this.CheckCompBy();
      this.CheckDetails();
      if (
        this.CheckStudent() == true &&
        this.CheckCatID() == true &&
        this.CheckAbout() == true &&
        this.CheckCompBy() == true &&
        this.CheckDetails() == true
      ) {
        this.request = true;
        // console.log("ob", this.compObj);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "SchoolComplaints?db=" +
            this.$store.state.userData.db,
          body: this.compObj,
          message: "Complaint added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;

        if (status) {
          this.LoadGrid(0);
          this.visibility3 = false;
        }
      }
    },

    resolve(item) {
      this.resObj = item;
      if (item.status == "resolved") {
        this.disabledFields = true;
      } else {
        this.disabledFields = false;
      }
      this.visibility2 = true;
      var elem = this.$refs["note"];
      elem.state = undefined;
      var elem = this.$refs["date"];
      elem.state = undefined;
      var elem = this.$refs["resBy"];
      elem.state = undefined;
    },
    CheckNote() {
      var elem = this.$refs["note"];
      if (this.resObj.note == "" || this.resObj.note == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDate() {
      var elem = this.$refs["date"];
      if (this.resObj.resolvedOn == "" || this.resObj.resolvedOn == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckResName() {
      var elem = this.$refs["resBy"];
      if (this.resObj.resolvedBy == "" || this.resObj.resolvedBy == null) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    async LoadGrid(st) {
      // console.log(st);
      this.currentFilter = st;
      this.gridLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Complaints/Filters?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&status=" +
          st,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);

      // console.log("items", this.items);
      this.gridLoading = false;
    },

    async save() {
      // console.log("ob", this.resObj);
      this.CheckNote();
      this.CheckDate();
      this.CheckResName();
      if (
        this.CheckNote() == true &&
        this.CheckDate() == true &&
        this.CheckResName() == true
      ) {
        this.request = true;
        this.resObj.status = "resolved";
        var status = await this.put({
          url:
            this.$store.state.domain +
            "Complaints/" +
            this.resObj.id +
            "?db=" +
            this.$store.state.userData.db,
          body: this.resObj,
          message: "Complaint resolved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;

        if (status) {
          this.LoadGrid(this.currentFilter);
          this.visibility2 = false;
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "ComplaintCategories/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Complaint category removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
